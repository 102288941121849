<template>
<div class="accordion">
	<AccordionPanel
		v-for="(panel, panelIndex) in panels"
		:key="panelIndex"
		:title="panel.title"
		:content="panel.content"
		:contentElements="panel.contentElements"
	/>
</div>
</template>

<script>
import AccordionPanel from './AccordionPanel';

export default {
	name: 'Accordion',
	components: {
		AccordionPanel
	},
	props: {
		panels: {
			type: Array,
			required: true,
			default: () => {}
		}
	}
};
</script>
