<template>
	<VueFoldable class="foldable" height="0px" async>

		<!-- default slot -->
		<slot />

		<!-- named slot -->
		<template
			slot="view-more"
			slot-scope="{ toggle, collapsed }"
		>
			<div
				@click="toggle"
				class="view-more"
				:class="{ 'collapsed': collapsed }"
			>
 				<font-awesome-icon
					:icon="['fal', 'chevron-circle-down']"
					class="view-more-icon"
				/>
				<span class="view-more-text">
					{{ collapsed ? 'Mehr Lesen' : 'Weniger Lesen' }}
				</span>
			</div>
		</template>
	</VueFoldable>
</template>

<script>
	import VueFoldable from 'vue-foldable';
	export default {
		name: 'foldable',
		components: { VueFoldable }
	};
</script>

<style lang="scss">
	$view-more--icon-font-size: rem(20) !default;
	$view-more--icon-color: color(primary, base) !default;
	$view-more--height: 34px !default;
	$view-more--padding: 8px !default;
	$view-more--background-color: color(white) !default;
	$view-more--mask: 0 !default;
	$vue-foldable-mask-height: 100% !default;
	$view-more--mask-background: linear-gradient(to bottom, rgba(55,55,55,0), #fff) !default;

	.vue-foldable.foldable {

 		position: relative;
		.vue-foldable-container {
			transition: max-height 0.3s;
			overflow: hidden;
		}

		.vue-foldable-mask {
			transition: opacity 3s;
			top: $view-more--mask;
			position: absolute;
			height: $vue-foldable-mask-height;
			width: 100%;
			background: transparent;
			pointer-events: none;
		}

		.view-more {
			color: $view-more--icon-color;
			padding: 0 $view-more--padding;
			font-size: $view-more--icon-font-size;
			height: $view-more--height;
			line-height: $view-more--height;
			background-color: $view-more--background-color;
			transition: background-color .1s ease-in-out;
			text-align: center;
			cursor: pointer;
			position: relative;


			&.collapsed {
				top: -100px;
			}

			.view-more-icon {
				vertical-align: -5px;
			}

			.view-more-text {
				vertical-align: middle;
			}

			&:not(.collapsed) {
				.view-more-icon {
					transform: scaleY(-1);
				}
			}
		}
	}

	.vue-foldable {
	.vue-foldable-mask {
		&.collapsed {
			opacity: 1;
			background: $view-more--background-color;
			transition: none;
		}
		&:not(.collapsed) {
			opacity: 0;
		}
	}
	.vue-foldable-view-more {

		width: 100%;
		text-align: center;
		height: 30px;
		line-height: 30px;
		cursor: pointer;

		.vue-foldable-icon {
			width: 22px;
			transform: rotate(180deg);
			transition: transform 0.3s;
			vertical-align: middle;
		}

		.vue-foldable-text {
			vertical-align: middle;
		}

		&.collapsed .vue-foldable-icon {
			transform: rotate(0deg);
		}
	}
}

</style>
