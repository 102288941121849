<template>
<div
	class="accordion-panel"
	role="tablist"
>
	<div
		v-b-toggle="identifier"
	>
		<div class="accordion-panel--header">
			<span class="accordion-panel--header-text">
				{{title}}
			</span>
		</div>
	</div>

	<b-collapse
		:id="identifier"
		role="tabpanel"
	>
		<div class="accordion-panel--content">
			<ce-renderer
				:content="contentElements"
				v-if="contentElements.length > 0"
			/>
			{{content}}
		</div>
	</b-collapse>

</div>
</template>

<script>
import { uuid } from '~/plugins/utilities/StringUtility';

export default {
	name: 'AccordionPanel',
	props: {
		title: {
			type: String,
			required: true
		},
		content: {
			type: String
		},
		contentElements: {
			type: Array,
			default: []
		},
		identifier: {
			type: String,
			default: function() {
				return 'accordion-'+ uuid();
			}
		}
	}
};
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$accordion-panel--paddding: rem(20) !default;
	$accordion-panel--header-font-size: rem(40) !default;
	$accordion-panel--header-color: color(primary, base) !default;
	$accordion-panel--background: color(white) !default;
	$accordion-panel--border: 1px solid color(primary, light) !default;
	$accordion-panel--border-active: 1px solid color(secondary, dark) !default;
	$accordion-panel--icon: times-circle !default;
	$accordion-panel--icon-color: color(secondary, dark) !default;
	$accordion-panel--icon-color-active: color(primary, base) !default;

	.accordion-panel {

		&--header,
		&--content {
			padding: $accordion-panel--paddding;
		}

		.collapsed,
		.not-collapsed {
			.accordion-panel--header {
				&:after {
					position: absolute;
					right: 0;
					margin-right: $accordion-panel--paddding;
					color: $accordion-panel--icon-color;
				}
			}
		}
	}

	.accordion-panel {
		border-bottom: $accordion-panel--border;
		&:first-child {
			border-top: $accordion-panel--border;
		}



		.accordion-panel--header {
			position: relative;
			@include responsive-type(2, 1.5);
			color: $accordion-panel--header-color;
			display: flex;
			align-items: center;

			&:after {
				font-family: 'Font Awesome 5 Pro';
				content: fa-content($fa-var-times-circle);
				color: $accordion-panel--icon-color-active;
				transform: rotate(45deg);
  				transition: background-color 0.5s ease;
			}

			.accordion-panel--header-text {
					@include media-breakpoint-down(sm) {
						display: block;
						max-width: 90%;
						line-height: 32px;
					}
			}

		}
		.not-collapsed > .accordion-panel--header {
			background: $accordion-panel--background;
			border-bottom: none;

			&:after {
				transform: rotate(0deg);
				color: $accordion-panel--icon-color-active;
			}
		}

		.not-collapsed > .accordion-panel--header,
		.collapse.show > .accordion-panel--content {
			background: $accordion-panel--background;
			transition: background-color 0.5s ease;
		}

		.accordion-panel--content {
			.frame-default {
				padding: 0;
			}
		}

	}
</style>
