<template>
	<div class="bubble-teaser-container">
		<div class="row">
			<div class="col-lg-6 col-xl-7">
				<div
					class="bubble-teasers bubble-teasers--spokes pe-xxl-5"
					data-aos="fade-right"
					data-aos-anchor-placement="center-bottom"
					data-aos-duration="1000"
				>
					<div
						class="bubble-teasers--item"
						v-for="(item, itemKey) in items"
						:key="itemKey"
						:class="{
							active: itemKey === activeItem,
						}"
					>
						<button
							@click="setActiveItem(itemKey)"
							aria-label="Zeige Inhalt"
							class="btn btn-link"
						>
							<div class="bubble-teasers--content">
								<font-awesome-icon
									:icon="['isd', item.content.icon]"
								/>
								<div
									class="bubble-teasers--content-headline"
									v-html="item.content.header"
								></div>
							</div>
						</button>
					</div>
				</div>
			</div>

			<div
				class="col-xl-5 col-lg-6 d-flex align-items-center mb-sm-10"
				data-aos="fade-left"
				data-aos-anchor-placement="center-bottom"
				data-aos-duration="1000"
			>
				<div class="headline d-flex flex-column">
					<h2 class="mb-4 circle-tabs--header">
						{{ header }}
					</h2>
					<transition
						name="fade"
						mode="out-in"
						appear
						:duration="800"
					>
						<tab-content
							v-for="(item, itemKey) in items"
							:key="'content' + itemKey"
							v-if="itemKey == activeItem"
							inline-template
						>
							<div class="circle-tabs--content">
								<h3
									class="mb-4 circle-tabs--subheader"
									v-html="item.content.header"
								></h3>

								<html-parser :content="item.content.bodytext" />
							</div>
						</tab-content>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BubbleTeaser',
	props: {
		header: {
			type: String,
			required: true,
		},
		items: {
			type: Array,
			required: true,
			default: () => {},
		},
	},
	data() {
		return {
			activeItem: 0,
		}
	},
	methods: {
		setActiveItem(item) {
			this.activeItem = item
		},
	},
	components: {
		TabContent: {},
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$bubble-teasers--item--width-sm: rem(60) !default;
$bubble-teasers--item--width: 30% !default;
$bubble-teasers--item--width-lg: 28% !default;
$bubble-teasers--item--before-width: 80px !default;
$bubble-teasers--item--before-width-lg: 50px !default;
$bubble-teasers--item--color: color(primary, base) !default;
$bubble-teasers--item--background-color: color(white) !default;
$bubble-teasers--icon-font-size: rem(40) !default;
$bubble-teasers--icon-font-size-lg: rem(15) !default;
$bubble-teasers--icon-color: color(primary, light) !default;
$circle-inner--item-margin: rem(30) !default;
$circle-inner--item-animation-margin: 100px !default;
$circle-tabs--subheader-color: color(primary, light) !default;

$transition-duration: 0.24s;
$transition-timing: cubic-bezier(0.86, 0, 0.07, 1);

$delay: 100ms;
$delayStep: 100ms;

.bubble-teasers {
	@include media-breakpoint-down(md) {
		display: grid;
		grid-template-columns: 2fr 2fr 2fr;
		grid-template-rows: 1fr 1fr 1fr;
		> * {
			justify-self: center;
		}
	}
}

.bubble-teasers--item {

	.btn-link {
		padding: 0;
	}

	display: inline-flex;

	width: $bubble-teasers--item--width * 0.8;
	padding-bottom: $bubble-teasers--item--width * 0.8;

	@include media-breakpoint-down(md) {
		justify-items: center;

		width: $bubble-teasers--item--width-sm;
		padding-bottom: $bubble-teasers--item--width-sm;
	}

	@include media-breakpoint-up(md) {
		width: $bubble-teasers--item--width;
		padding-bottom: $bubble-teasers--item--width;
		margin: 10px;
	}

	@include media-breakpoint-only(lg) {
		width: $bubble-teasers--item--width-lg;
		padding-bottom: $bubble-teasers--item--width-lg;
	}

	cursor: pointer;

	background-color: $bubble-teasers--item--background-color;
	border-radius: 50%;
	height: 0;
	position: relative;
	margin: 15px 15px 80px 15px;
	transition: all 0.5s ease !important;

	.bubble-teasers--content {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		text-align: center;
		color: $bubble-teasers--item--color;
		transform: translateY(25%);

		@include media-breakpoint-between(md, lg) {
			transform: translateY(30%);
		}

		@include media-breakpoint-only(lg) {
			transform: translateY(30%);
		}

		.svg-inline--fa {
			font-size: rem(30);
			color: $bubble-teasers--icon-color;

			@include media-breakpoint-up(md) {
				font-size: $bubble-teasers--icon-font-size * 1.2;
				margin-bottom: 0;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 10px;
			}
		}

		.bubble-teasers--content-headline {
			width: 60%;
			margin: 0 auto;
			line-height: 22px;
			font-weight: 800;
			transition: all 0.5s ease !important;
			font-size: rem(18);

			@include media-breakpoint-up(md) {
				font-size: rem(19);
			}

			@include media-breakpoint-only(md) {
				margin-top: 10px;
			}

			@include media-breakpoint-down(md) {
				font-size: rem(13);
				width: 100%;
				line-height: 18px;
				margin-top: rem(24);
				margin-bottom: $bubble-teasers--icon-font-size;
			}

			@include media-breakpoint-only(lg) {
				display: none;
			}
		}
	}

	&:hover,
	&.active {
		background: $bubble-teasers--icon-color;
		color: $bubble-teasers--item--background-color;
		box-shadow: 0 0 0 10px $bubble-teasers--icon-color;
		z-index: 2;

		.bubble-teasers--content,
		.svg-inline--fa {
			color: $bubble-teasers--item--background-color;
		}

		@include media-breakpoint-down(md) {
			.bubble-teasers--content-headline {
				color: $bubble-teasers--item--color;
			}
		}

		&:before {
			content: '';
			position: absolute;

			@include media-breakpoint-up(md) {
				width: $bubble-teasers--item--before-width;
				height: $bubble-teasers--item--before-width;
				margin-top: -$circle-inner--item-animation-margin / 3;
			}

			background: $bubble-teasers--item--background-color;
			border-radius: 50%;
			right: 0;
			opacity: 0.5;

			@include media-breakpoint-only(lg) {
				width: $bubble-teasers--item--before-width-lg;
				height: $bubble-teasers--item--before-width-lg;
				margin-top: -$circle-inner--item-animation-margin / 4;
			}

			@include media-breakpoint-down(md) {
				width: $bubble-teasers--item--before-width / 2.5;
				height: $bubble-teasers--item--before-width / 2.5;
				margin-top: -$circle-inner--item-animation-margin / 5;
			}

			.bubble-teasers--content-headline {
				color: $bubble-teasers--item--background-color;
			}
		}
	}
}

.circle-tabs--header {
	@include responsive-type(4, 1.2);
}

.circle-tabs--subheader {
	@include responsive-type(3, 1.2);
	color: $circle-tabs--subheader-color;
}

.bubble-teasers--spokes {
	margin-top: $circle-inner--item-animation-margin / 3;
	margin-bottom: $circle-inner--item-animation-margin / 3;

	@include media-breakpoint-up(md) {
		margin-bottom: $circle-inner--item-animation-margin;
		margin-top: $circle-inner--item-animation-margin;
	}

	@include media-breakpoint-only(lg) {
		margin-top: $circle-inner--item-animation-margin / 5;
	}

	.bubble-teasers--item {
		@include media-breakpoint-down(md) {
			//@INFO: No paralax animation on Mobile
			transform: none !important;
		}

		@include media-breakpoint-up(md) {
			&:first-of-type {
				top: $circle-inner--item-animation-margin;
			}

			&:nth-of-type(3) {
				top: $circle-inner--item-animation-margin;
			}

			&:nth-of-type(4) {
				top: $circle-inner--item-animation-margin;
			}

			&:nth-of-type(6) {
				top: $circle-inner--item-animation-margin;
			}

			&:nth-of-type(7) {
				left: 230px;

				@include media-breakpoint-only(lg) {
					left: 150px;
				}
			}
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	& > * {
		transition-duration: 200ms;
		transition-property: opacity, transform;
		transition-timing-function: cubic-bezier(0.6, 0.15, 0.35, 0.8);
	}
}

.fade-enter,
.fade-leave-to {
	& > * {
		opacity: 0;
		transform: translateY(40px);
	}
}

.fade-enter-active {
	& > * {
		&:nth-child(2) {
			transition-delay: $delay;
		}

		&:nth-child(3) {
			transition-delay: $delay + $delayStep;
		}
	}
}

.fade-leave-active {
	& > * {
		&:nth-child(1) {
			transition-delay: $delay + $delayStep;
		}

		&:nth-child(2) {
			transition-delay: $delay;
		}
	}
}
</style>
