<template>
	<div class="germanymap">
		<div class="germanymap__map-wrapper">
			<MapSVG />
		</div>
		<div class="germanymap__item-wrapper">
			<div
				class="germanymap-item d-none"
				v-for="(item, itemKey) in items"
				:key="itemKey"
			>
				<media :query="{ minWidth: 992 }">
					<div class="germanymap-item__body-wrapper">
						<div class="germanymap-item__sidebar">
							<h3 class="germanymap-item__header">
								<html-parser :content="item.content.header" />
							</h3>
							<div class="germanymap-item__sidebartext">
								<html-parser
									:content="
										item.content
											.tx_mask_germanymap_item_sidebar
									"
								/>
							</div>
						</div>
						<div class="germanymap-item__body">
							<div class="germanymap-item__image">
								<img
									v-if="item.content.image[0]"
									:src="item.content.image[0].publicUrl"
									:alt="
										item.content.image[0].properties
											.alternative
									"
								/>
							</div>
							<div class="germanymap-item__bodytext">
								<html-parser :content="item.content.bodytext" />
							</div>
						</div>
					</div>
				</media>
				<media :query="{ maxWidth: 991 }">
					<div class="germanymap-item__body-wrapper">
						<div class="germanymap-item__sidebar">
							<div class="germanymap-item__body">
								<div class="germanymap-item__image">
									<img
										v-if="item.content.image[0]"
										:src="item.content.image[0].publicUrl"
										:alt="
											item.content.image[0].properties
												.alternative
										"
									/>
								</div>
								<h3 class="germanymap-item__header">
									<html-parser
										:content="item.content.header"
									/>
								</h3>
								<div class="germanymap-item__sidebartext">
									<html-parser
										:content="
											item.content
												.tx_mask_germanymap_item_sidebar
										"
									/>
								</div>
								<div class="germanymap-item__bodytext">
									<html-parser
										:content="item.content.bodytext"
									/>
								</div>
							</div>
						</div>
					</div>
				</media>
			</div>
		</div>
	</div>
</template>

<script>
import MapSVG from '~/components/molecules/collapsible/GermanymapSVG.vue'
import Media from 'vue-media'
export default {
	name: 'GermanymapItem',
	props: {
		header: {
			type: String,
			required: true,
		},
		items: {
			type: Array,
			required: true,
			default: () => {},
		},
	},
	components: {
		MapSVG,
		Media,
	},
	watch: {
		$route: 'selectDefaultRegion',
	},
	methods: {
		setActiveItem(item) {
			this.activeItem = item
		},
		selectDefaultRegion() {
			this.$nextTick(() => {
				const defaultRegion = document.getElementById('region2')
				if (defaultRegion) {
					let event = new Event('click', {
						bubbles: true,
						cancelable: true,
					})
					defaultRegion.dispatchEvent(event)
				}
			})
		},
		setupMapInteraction() {
			const germanymapItems =
				document.querySelectorAll('.germanymap-item')
			const regions = document.querySelectorAll(
				'.germanymap__map-wrapper #flaechen g.st0'
			)

			let i = 0
			regions.forEach((element) => {
				let currentI = i
				element.addEventListener('click', function () {
					regions.forEach((g) =>
						g.classList.toggle('germanymap--blue', false)
					)
					germanymapItems.forEach((item) =>
						item.classList.toggle('d-none', true)
					)
					this.classList.toggle('germanymap--blue', true)
					if (germanymapItems[currentI]) {
						germanymapItems[currentI].classList.toggle(
							'd-none',
							false
						)
					}
				})
				i++
			})
		},
	},
	mounted() {
		if (process.browser) {
			this.setupMapInteraction()
			this.selectDefaultRegion()
		}
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';

.germanymap {
	display: flex;
	flex-direction: column;

	&__item-wrapper {
		.germanymap-item {
			&__body {
				display: flex;
				flex-direction: column;
			}

			&__bodytext {
				line-height: 1.2;
			}

			&__sidebartext {
				h3 {
					margin-top: 3rem;
				}

				h4 {
					color: $isd--blue-light;
				}
				blockquote {
					@include media-breakpoint-down(md) {
						margin: 0 0 2rem;
					}
				}
			}

			&__header {
				margin-bottom: 1rem;
			}

			&__image {
				margin: 1rem 0 2rem;
			}
		}
	}

	&__map-wrapper {
		margin-bottom: 1rem;
	}

	@include media-breakpoint-up(sm) {
		flex-direction: row;
		margin: 0 -3rem 0;

		&__item-wrapper {
			width: 60%;
			margin: 0 3rem 0;

			.germanymap-item {
				&__body-wrapper {
					display: flex;
					flex-direction: row-reverse;
				}

				&__sidebar {
					width: 40%;
					margin-left: 2rem;
				}

				&__body {
					width: 60%;
				}

				&__header {
					margin-bottom: 2rem;
				}

				&__image {
					margin-top: 0;
				}
			}
		}

		&__map-wrapper {
			width: 40%;
			margin: 0 3rem;
		}
	}
}
</style>
