var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FormulateForm',{class:_vm.data.form.css,attrs:{"action":_vm.data.form.action},on:{"submit":_vm.submit}},_vm._l((_vm.formulateSchema),function(page,pageIndex){return _c('fieldset',{key:pageIndex,class:[
				'form-page',
				`form-page-${page.id}`,
				page.additonalClasses,
			]},[_c('legend',[_vm._v(_vm._s(page.title))]),_vm._v(" "),_c('div',{staticClass:"row gy-5 gx-5"},_vm._l((page.fields),function(field,fieldIndex){return _c('div',{key:fieldIndex,class:[
						'col',
						'col-12',
						field.additonalClasses,
					]},[_c('FormulateInput',_vm._b({},'FormulateInput',field,false))],1)}),0)])}),0),_vm._v(" "),(_vm.state.success)?[_c('div',{staticClass:"formulate-success"},[_vm._v("Thank you!")])]:_vm._e(),_vm._v(" "),(_vm.state.error)?[_c('div',{staticClass:"formulate-error"},[_vm._v("Error occurred")])]:_vm._e(),_vm._v(" "),(_vm.state.loading)?[_c('div',{staticClass:"formulate-loading"},[_vm._v("loading...")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }