
<template>
	<div>
		<!--
		<pre>{{formulateSchema}}</pre>
		-->
		<FormulateForm
			@submit="submit"
			:action="data.form.action"
			:class="data.form.css"
		>
			<fieldset
				v-for="(page, pageIndex) in formulateSchema"
				:key="pageIndex"
				:class="[
					'form-page',
					`form-page-${page.id}`,
					page.additonalClasses,
				]"
			>
				<legend>{{page.title}}</legend>
				<div class="row gy-5 gx-5">
					<div
						v-for="(field, fieldIndex) in page.fields"
						:key="fieldIndex"
						:class="[
							'col',
							'col-12',
							field.additonalClasses,
						]"
					>
						<FormulateInput
							v-bind="field"
						/>
					</div>
				</div>
			</fieldset>
		</FormulateForm>


		<template v-if="state.success">
			<div class="formulate-success">Thank you!</div>
		</template>

		<template v-if="state.error">
			<div class="formulate-error">Error occurred</div>
		</template>

		<template v-if="state.loading">
			<div class="formulate-loading">loading...</div>
		</template>

	</div>
</template>

<script>
	// Map EXT:powermail input types to vue formulate
	const POWERMAIL_TYPE_MAPPING = {
		// powermail type : Formulate type
		text: 'text',
		email: 'email',
		url: 'url',
		tel: 'tel',
		// country: 'text',
		// location: 'text',
		textarea: 'textarea',
		password: 'password',
		number: 'number',
		date: 'date',
		select: 'select',
		file: 'file',
		check: 'checkbox',
		radio: 'radio',
		html: 'html',
		submit: 'submit',
	};

	// Map EXT:powermail validation rules to vue formulate
	const POWERMAIL_VALIDATION_MAPPING = {
		EmailAddress: 'email',
		NotEmpty: 'required',
		Number: 'number'
	};

	export default {
		props: {
			data: {
				type: Object,
				required: true,
				default: () => {}
			}
		},
		data() {
			return {
				state: {
					success: false,
					error: false,
					loading: false
				}
			}
		},
		computed: {
			hiddenFields () {
				return Object.keys(this.data.form.fields.hiddenFields).map((key) => {
					const hiddenField = {};
					const field = this.data.form.fields.hiddenFields[key];
					hiddenField[field.name] = field.value;
					return hiddenField;
				});
			},
			formulateSchema () {
				const pages = JSON.parse(JSON.stringify(this.data.form.fields.pages));

				const schema = [];
				pages.forEach((page) => {

					const pageSchema = {
						title: page.title,
						id: page.pageId,
						additonalClasses: page.css,
						fields: [],
					};

					page.fields.forEach((item) => {

						const fieldSchema = {
							type: POWERMAIL_TYPE_MAPPING[item.field.type] || 'hidden',
							name: item.field.name,
							value: item.field.value,
							label: item.field.label?.title,
							help: item.field.label?.description,
							// 'help-position': 'before',
							placeholder: item.field.placeholder,

							additonalClasses: item.field.css || item.field.label?.css,
						};
						if (item.field.id) {
							fieldSchema.id = item.field.id;
						}

						// @FIXME: find a way to render normal text for type "text"
						// @FIXME: find a way to render normal html for types "html" and "typoscript"
						// @FIXME: find a way to render content elements for type "content"

						// @FIXME: What about captcha?! this could be ignored for now…

						if (item.field.options) {
							fieldSchema.options = item.field.options;
							fieldSchema.value = item.field.options.reduce((accumulator, option) => {
								if (option.isSelected || option.checked) {
									return option.value;
								}
							});

							if (
								['checkbox', 'radio'].indexOf(fieldSchema.type) >= 0
								&& item.field.options.length === 1
							) {
								fieldSchema.value = item.field.options[0].value;
								fieldSchema.label = item.field.options[0].label;
								fieldSchema.checked = item.field.options[0].checked;
								fieldSchema.options = undefined;
							}
						}

						// @FIXME: Add proper validation string
						fieldSchema.validation = []
						//fieldSchema.validators.map((validator) => {
						//	fieldSchema.validation.push(POWERMAIL_VALIDATION_MAPPING[validator.identifier]
						//});
						if (item.field.label?.mandatory) {
							fieldSchema.validation.push('required');
						}


						fieldSchema.validation =
							fieldSchema.validation.length > 0
							? fieldSchema.validation.filter((v, i, a) => a.indexOf(v) === i).join('|')
							: ''
						;

						// @TODO: add errors if an server-side error occurs
						// See schema.validation‑messages

						fieldSchema['validation-messages'] = {};
						if (item.field.additionalAttributes && item.field.additionalAttributes['data-parsley-error-message']) {
							fieldSchema['validation-messages'].required = item.field.additionalAttributes['data-parsley-error-message'];
						}

						if (
							['checkbox', 'radio'].indexOf(fieldSchema.type) >= 0
							&& item.field.options.length === 1
							&& item.field.options[0].additionalAttributes
							&& item.field.options[0].additionalAttributes['data-parsley-required-message']
						) {
							fieldSchema['validation-messages'].required = item.field.options[0].additionalAttributes['data-parsley-required-message'];
						}

						pageSchema.fields.push(fieldSchema);
					});

					schema.push(pageSchema);
				});

				return schema;
			},
		},
		methods: {
			isValidType (type) {
				return POWERMAIL_TYPE_MAPPING[type] !== undefined;
			},
			toggleState (state, value) {
				this.state[state] = value || !this.state[state]
			},
			resetState () {
				Object.keys(this.state).map(state => (this.state[state] = false))
			},
			submit (data) {
				this.resetState();
				this.toggleState('loading');
				this.$typo3.api.$http
					.$post(this.data.form.fields.action.replace(/^\/api/,''), {
						...data,
						...Object.assign({}, ...this.hiddenFields)
					})
					.then(data => {
						// this.$formulate.reset(this.form.id)
						this.toggleState('loading')
						this.toggleState('success')
					})
					.catch(() => {
						this.toggleState('loading')
						this.toggleState('error')
					})
				;
			}
		}
	}
</script>

